import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { uploadStatusMapping } from '../const';
import { reFormat } from '../utils';


const UploadedByCell = ({ attempt }) => {
    const classes = useStyles({ status: attempt.attempt_status });

    return (
        <div className={classes.root}>
            <div className={classes.status}>
                {attempt.attempt_status}
            </div>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },

    status: (props) => {
        const backColor = uploadStatusMapping(theme)[props.status].main;

        return {
            width: '100px',
            height: '30px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '14px',
            color: backColor,
            backgroundColor: 'white',
            textTransform: 'capitalize',
            borderRadius: '10px',
            border: `1px solid ${backColor}`,
            fontWeight: 'bold'
        };
    },
    type: {
        fontSize: '14px',
        color: '#666666',
        lineHeight: '15px',
    },
}));

export default UploadedByCell;


