import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import makeStyles from '@mui/styles/makeStyles';
import SideNavBar from './SideNavBar';
import Header from './Header';
import Widget from '../widgets';
import { useStores } from '@a2d24/care-at-work-state/stores';
import { promptUpdate } from '@a2d24/care-at-work-core/components/ReloadDialog';
import { getDefaultPageForRole } from './utils';

const Page = React.memo(({ config }) => {
    return React.createElement(React.memo(Widget), { config });
});
const CareRoutes = engine.providers('CareRoutes').map((generator) => generator());

const Base = ({ pages, menu }) => {
    const classes = useStyles();
    const { authStore } = useStores();
    return (
        // <Router>
        <div className={classes.root}>
            <Header />

            <div className={classes.container}>
                <SideNavBar className={classes.nav} menu={menu} />
                {promptUpdate(authStore)}
                <div className={classes.content}>
                    <Routes>
                        {CareRoutes}
                        {pages.map((page) => (
                            <Route
                                basename={page.id}
                                path={page.path}
                                key={page.id}
                                exact
                                element={<Page config={page.widget} />}
                            />
                        ))}
                        <Route path="/" element={<Navigate replace to={`/${getDefaultPageForRole(authStore.selectedRole.role)}`} />} />
                    </Routes>
                </div>
            </div>
        </div>
        // </Router>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    container: {
        display: 'flex',
        flex: 1,
        maxHeight: '100%',
        overflow: 'hidden',
    },
    nav: {
        width: '246px',
    },
    content: {
        flex: 1,
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
        overflow: 'auto',

        // Firefox
        scrollbarWidth: 'thin',
        scrollbarColor: '#999 #fff',

        // Chrome
        '&::-webkit-scrollbar': {
            width: '0.4em',
        },
        '&::-webkit-scrollbar-track': {
            background: '#fff',
        },

        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#999',
        },
    },
}));

export default Base;
