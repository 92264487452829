import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import makeStyles from '@mui/styles/makeStyles';
import JobRoleUploader from './JobRoleUploader';
import WarningIcon from '@mui/icons-material/Warning';


const JobRoleUploadDialog = ({ open, onClose}) => {
    const classes = useStyles();


    return (
        <Dialog
            maxWidth="md"
            fullWidth
            open={open}
            onClose={onClose}
            classes={{ paper: classes.dialogPaper }}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle>
                <div>Job Role CSV Uploader</div>
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{overflowY: 'hidden'}}>
                <div style={{display: 'flex', padding: '10px', alignItems: 'center', gap: '5px'}}>
                    <WarningIcon/>
                    <div> Convert the file to CSV format before uploading </div>
                </div>
                <JobRoleUploader/>
            </DialogContent>
        </Dialog>
    );
};

const useStyles = makeStyles((theme) => ({
    dialogPaper: {
        minHeight: '50vh',
        minWidth: '50vw',
        maxHeight: '50vh',
    },
}));

export default JobRoleUploadDialog;
