import MaterialTable from '@material-table/core';
import { TableContainer } from '@mui/material';
import { AlternativesMedicalTypeCell } from './MedicalTypeCell';
import { AlternativesMedicalDescriptionCell } from './MedicalDescriptionCell';
import styles from './styles';
import CellButton from './CellButton';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { createConsult } from './Api/createConsult';

const AlternativeSubTable = ({ booking, patient, data, activityId }) => {
    const classes = styles();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const handleProceed = (rowData) => {
        createConsult(
            booking.booking_id,
            patient,
            rowData.tariff_code,
            rowData.medical_type,
            activityId
        )
            .then((res) => {
                enqueueSnackbar('Registration Successful', { variant: 'success' });
                navigate('/dashboard');
            })
            .catch((err) => {
                enqueueSnackbar('Failed to create consult', { variant: 'error' });
            });
    };
    const columns = [
        {
            field: 'consult_type',
            render: (rowData) => <AlternativesMedicalTypeCell rowData={rowData} />,
        },

        {
            field: 'medical_description',
            render: (rowData) => <AlternativesMedicalDescriptionCell rowData={rowData} />,
        },

        {
            field: 'action_proceed',
            render: (rowData) => (
                <CellButton label="Proceed" onClick={(event) => handleProceed(rowData)} />
            ),
        },
    ];
    return data.length > 0 ? (
        <MaterialTable
            title={
                <div className={classes.alternativesHeader}>
                    Alternative Medicals: Select an alternative option if the recommended option is
                    not relevant for this consultation
                </div>
            }
            columns={columns}
            data={data}
            options={{
                showTitle: true,
                showEmptyDataSourceMessage: true,
                filtering: false,
                header: false,
                paging: false,
                search: false,
                selection: false,
                pageSize: data.length,
            }}
            components={{
                Container: (props) => (
                    <TableContainer {...props} className={classes.alternativesContainer} />
                ),
            }}
        />
    ) : null;
};

export default AlternativeSubTable;
