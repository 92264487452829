import React, { useContext, useState, useEffect } from 'react';
import { Form } from 'react-final-form';
import Recycle from '@a2d24/care-at-work-core/Recycle';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { AppConfigContext } from '../../App';
import { useStores } from '@a2d24/care-at-work-state/stores';
import makeStyles from '@mui/styles/makeStyles';
import ManualBookingForm from './FormsandPages/ManualBookingForm';
import { toDate } from 'date-fns-tz';
import { startOfDay } from 'date-fns';


const ManualBooking = ({ formRef, props }) => {
    // const patient_id = `${patient.identifier_type}_${patient.identifier}`;
    const navigate = useNavigate();
    const classes = useStyles();
    const appConfig = useContext(AppConfigContext);
    const { enqueueSnackbar } = useSnackbar();
    const { authStore } = useStores();
    const [patient, setPatient] = useState(null);
    const [showPatientDetails, setShowPatientDetails] = useState(false);
    const [initialFormValues, setInitialFormValues] = useState({});
    const [prepopulationMeta, setPrepopulationMeta] = useState({});
    const [searchParams, setSearchParams] = useSearchParams();
    useEffect(
        () => {
            if (searchParams.size > 0) {
                const prepopulationParams = {};
                searchParams.forEach((value, key) => {
                    if (key === "prepopulation_meta") {
                        setPrepopulationMeta(JSON.parse(decodeURI(value)));
                    } else {
                        prepopulationParams[key] = decodeURI(value);
                    }
                });
                setInitialFormValues(prepopulationParams);
            }
        }
        , []
    )

    const handleSearch = (values) => {
        let consult_type = null;
        if (props?.booking !== undefined) {
            consult_type = props.booking.consult_type;
        }
        Recycle.rpcPostV2({
            controller: 'PatientController',
            method: 'search_patient',
            kwargs: { identifier_type: values.identifier_type, identifier: values.identifier },
        })
            .then((res) => {
                setInitialFormValues({
                    ...res,
                    identifier_type: 'MR',
                    identifier: res.primary_identifier.value,
                    consult_type: consult_type,
                });
                setPatient(res);
                setShowPatientDetails(true);
            })
            .catch((err) => {
                if (err.response.status === 404) {
                    setInitialFormValues({
                        ...values,
                        consult_type: consult_type,
                    });
                    setShowPatientDetails(true);
                }
            });
    };
    const handleConfirmBooking = (formValues) => {
        const sendSMS = formValues?.send_sms;
        let submissionValues = {
            identifier_type: formValues.identifier_type,
            identifier: formValues.identifier,
            patient_first_name: formValues.first_name,
            patient_last_name: formValues.last_name,
            gender: formValues.gender,
            email: formValues.email,
            contact_number: formValues.contact_number,
            client_id: formValues.client,
            consult_type: formValues.consult_type,
            division: formValues.division,
            employment_site: formValues.employment_site,
            booking_service_date: startOfDay(
                toDate(formValues.service_date, { timeZone: 'Africa/Johannesburg' })
            ),
        };
        if (formValues.procedures) {
            submissionValues['procedures'] = formValues.procedures.map((p) => p.procedure_id);
        }
        if (formValues.consult_type === 'occupational_health_consult_with_cof') {
            submissionValues['medical_role'] = formValues.medical_role;
            submissionValues['job_role'] = formValues.job_role;
        }
            if (['chronic_care_consult', 'workplace_disease_consult'].includes(formValues.consult_type)) {
                submissionValues['disease_type'] = formValues.disease_type;
            }
            // This is an injected props for bookings created via the api
            if (props?.booking?.booking_id !== undefined) {
                submissionValues['booking_id'] = props.booking.booking_id;
                submissionValues['additional_meta'] = props.booking.metadata;
            }
            if (Object.keys(prepopulationMeta).length > 0) {
                submissionValues['prepopulation_meta'] = prepopulationMeta
            }

            Recycle.rpcPostV2({
                controller: 'BookingController',
                method: 'create_manual_booking',
                kwargs: submissionValues,
            })
                .then((manual_booking_res) => {
                    enqueueSnackbar('Booking Created', { variant: 'success' });
                    appConfig.setState({
                        ...appConfig,
                        currentBookingID: manual_booking_res.booking_id,
                    });
                    if (!patient) {
                        Recycle.rpcPostV2({
                            controller: 'PatientController',
                            method: 'save_patient',
                            kwargs: { patient: formValues },
                        })
                            .then((save_patient_res) => {
                                navigate(
                                    `/booking/${manual_booking_res.booking_id}/consent/${save_patient_res.patient.primary_identifier.value}`,
                                    {
                                        state: {
                                            sendSMS: sendSMS
                                        }
                                    }
                                );
                            })
                            .catch((err) => {
                                console.log(err.response.data.message);
                            });
                    } else {
                        Recycle.rpcPostV2({
                            controller: 'PatientController',
                            method: 'update_patient',
                            kwargs: {
                                patient_primary_identifier: patient.primary_identifier.value,
                                patient_first_name: formValues.first_name,
                                patient_last_name: formValues.last_name,
                                gender: formValues.gender,
                                email: formValues.email,
                                contact_number: formValues.contact_number,
                            },
                        })
                            .then((r) => {
                                navigate(
                                    `/booking/${manual_booking_res.booking_id}/consent/${patient.primary_identifier.value}`,
                                    {
                                        state: {
                                            sendSMS: sendSMS
                                        }
                                    }
                                )
                            })
                            .catch((err) => {
                                console.log(err.response.data.message);
                            });
                    }
                })
                .catch((err) => {
                    console.log(err.response);
                });
        }
        ;

        return (
            <div style={{ display: 'contents', position: 'relative', overflow: 'hidden' }}>
                {props.isRegistering ? null : (
                    <div>
                        <div className={classes.heading}>Create Booking</div>
                    </div>
                )}

                <Form
                    onSubmit={() => {
                    }}
                    internalSubmit={handleConfirmBooking}
                    initialValues={initialFormValues}
                    formRef={formRef}
                    component={ManualBookingForm}
                    prePopulate={{
                        0: handleSearch,
                    }}
                    prePopulatePageIndex={[0]}
                    setInitialValues={(values) => {
                        setInitialFormValues(values);
                    }}
                    authStore={authStore}
                />
            </div>
        );
};

export default ManualBooking;

const useStyles = makeStyles((theme) => ({
    buttonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginTop: theme.spacing(2),
    },
    updateButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        marginLeft: theme.spacing(1),
    },
    heading: {
        color: theme.palette.primary.main,
        fontSize: '48px',
        fontWeight: 'bold',
        marginBottom: theme.spacing(3),
    },
    sub_heading: {
        color: theme.palette.primary.main,
        fontSize: '16px',
        fontWeight: 'bold',
        marginBottom: theme.spacing(3),
    },
}));
