import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { uploadSummaryMapping } from '../const';

const SummaryBadge = ({statKey, statValue}) => {
        const classes = useStyles({ summaryItem: statKey });
        return(
            <div className={classes.summaryItem}>
                {statValue}
            </div>
        )

}

export default SummaryBadge;

const useStyles = makeStyles((theme) => ({
    summaryItem: (props) => {

        const foreColor = uploadSummaryMapping(theme)[props.summaryItem].main;
        const backColor = uploadSummaryMapping(theme)[props.summaryItem].light;

        return {
            width: '100px',
            height: '20px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: '14px',
            color: foreColor,
            backgroundColor: 'white',
            textTransform: 'capitalize',
            borderRadius: '5px',
            border: `1px solid ${foreColor}`
        };
    },
}));

