import React from 'react';
import { styled } from '@a2d24-ui/theme';

export const CopyIcon = styled(
    React.forwardRef(({ color = '#499BAD', ...props }, forwardedRef) => (
        <svg width="18px" height="18px" {...props} version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} fillRule="nonzero">
                <path d="M2.905 12.374h-1.44a.21.21 0 0 1-.21-.21V1.466a.213.213 0 0 1 .21-.21h10.7a.21.21 0 0 1 .209.21v1.44a.628.628 0 1 0 1.256 0v-1.44C13.63.656 12.974 0 12.165 0h-10.7C.656 0 0 .656 0 1.465v10.7c0 .809.656 1.465 1.465 1.465h1.44a.628.628 0 1 0 0-1.256z" />
                <rect x="4.37" y="4.37" width="13.63" height="13.63" rx="3.5" />
            </g>
        </svg>
    )),
    {}
);
