import React, { useState, useContext } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import ButtonBase from '@mui/material/ButtonBase';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Menu from '@mui/material/Menu';
import { AppConfigContext } from '../App';
import Recycle from '@a2d24/care-at-work-core/Recycle';
import List from '@mui/material/List';
import { ListItem, ListItemText, Divider } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';

import { useStores } from '@a2d24/care-at-work-state/stores';
import {
    OCCHEALTH_PREFERRED_ROLE_STORAGE_KEY,
    renderRoleText,
} from '@a2d24/care-at-work-state/stores/auth.store';
import { getDefaultPageForRole } from './utils';

const Header = () => {
    const appConfig = useContext(AppConfigContext);
    const { authStore } = useStores();
    const classes = useStyles();

    const handleLogout = () => {
        Recycle.logout();
        appConfig.setState({ ...appConfig, authenticated: false, initialized: false, user: null });
        authStore.logout();
    };

    return (
        <AppBar className={classes.root} position="static">
            <Toolbar style={{ backgroundColor: 'white' }}>
                <div className={classes.logoContainer}>
                    <img className={classes.logo} src="/logo.svg" alt="logo" />
                </div>
                <div className={classes.rightContainer}>
                    {authStore.user ? <ProfileButton onLogout={handleLogout} /> : null}
                </div>
            </Toolbar>
        </AppBar>
    );
};

const ProfileButton = ({ onLogout }) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [siteMenuOpen, setSiteMenuOpen] = React.useState(false);
    const navigate = useNavigate();
    const { authStore } = useStores();

    const showMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const logout = () => {
        handleClose();
        onLogout();
    };

    const handlePreference = () => {
        handleClose();
        navigate('care/preferences');
    };

    const changeSite = (site) => {
        authStore.setSelectedRole(site);
        navigate(getDefaultPageForRole(site.split("#")[1]));
        window.location.reload();
    };

    const renderSites = () => {
        if (!authStore?.availableSites) {
            return null;
        }

        return authStore?.availableSites?.map((site, idx) => {
            const site_id = site?.identifier;
            const site_name = site?.name;
            const site_short_id_split = site_id ? site_id.split('/') : '';
            const site_short_id = site_short_id_split.length > 2 ? site_short_id_split[site_short_id_split.length - 2] : null;
            const list_item_text = site_short_id_split.length > 3 ? `${site_name} (${site_short_id})`: site_name;
            return (
                <List key={site_id}>
                    <ListItem key={site_id} className={classes.nested}>
                        <ListItemText primary={list_item_text} />
                    </ListItem>
                    {renderRoles(site?.roles, site_id)}
                    {idx < authStore?.availableSites.length - 1 ? (
                        <Divider variant="middle" />
                    ) : null}
                </List>
            );
        });
    };

    const renderRoles = (roles) => {
        if (!roles) {
            return null;
        }

        return roles.map(({ identifier, role }) => {
            if (authStore.selectedRole.identifier === identifier) {
                return (
                    <ListItem
                        key={identifier}
                        dense
                        selected
                        button
                        className={clsx([classes.nested_twice])}
                        onClick={() => changeSite(identifier)}
                    >
                        <ListItemText secondary={renderRoleText(role)} />
                    </ListItem>
                );
            } else {
                return (
                    <ListItem
                        key={identifier}
                        dense
                        button
                        className={classes.nested_twice}
                        onClick={() => changeSite(identifier)}
                    >
                        <ListItemText secondary={renderRoleText(role)} />
                    </ListItem>
                );
            }
        });
    };

    return (
        <React.Fragment>
            <ButtonBase className={classes.profileButtonContainer} onClick={showMenu}>
                <AccountCircleIcon color="primary" />
                <div className={classes.profile}>
                    <div className={classes.fullName}>
                        {authStore.user.name.first_names} {authStore.user.name.last_name}
                    </div>
                    <div className={classes.role}>
                        {renderRoleText(authStore.selectedRole.role)}
                    </div>
                </div>
                <ArrowDropDownIcon color="primary" />
            </ButtonBase>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                PaperProps={{
                    style: {
                        width: '35ch',
                    },
                }}
            >
                <List>
                    <ListItem button onClick={() => setSiteMenuOpen(!siteMenuOpen)}>
                        <ListItemText primary="Roles" />
                        {siteMenuOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={siteMenuOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {renderSites()}
                        </List>
                    </Collapse>
                    <ListItem button onClick={handlePreference}>
                        <ListItemText primary="Preferences" />
                    </ListItem>
                    <ListItem button onClick={logout}>
                        <ListItemText primary="Logout" />
                    </ListItem>
                </List>
            </Menu>
        </React.Fragment>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        boxShadow: 'none',
    },
    nested: {
        paddingLeft: theme.spacing(4),
        paddingBottom: 0,
    },
    nested_twice: {
        paddingLeft: theme.spacing(6),
    },
    selected: {
        backgroundColor: '#e4e6ed',
    },
    logoContainer: {
        flex: 1,
    },
    logo: {
        height: '56px',
    },
    rightContainer: {
        display: 'flex',
    },
    profileButtonContainer: {
        display: 'flex',
        flexDirection: 'row',
    },
    profile: {
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
        textTransform: 'capitalize',
        textAlign: 'left',
    },
    role: {
        color: '#999999',
        fontSize: '9px',
    },
    fullName: {
        color: theme.palette.primary.main,
        fontSize: '12px',
    },
    divider: {
        height: '24px',
        marginRight: theme.spacing(1),
        marginLeft: theme.spacing(1),
    },
    bellContainer: {
        marginRight: theme.spacing(3),
    },
}));

export default Header;
