const theme = {
    palette: {
        warning: {
            main: '#e9bf7a',
            light: 'rgba(188,166,120,0.1)',
            dark: 'rgba(188,166,120,1)',
        },
        success: {
            main: '#6caa9c',
            light: 'rgba(94,157,143,0.1)',
            dark: 'rgba(94,157,143,1)',
        },
        error: {
            main: '#d29292',
            light: 'rgba(210,146,146,0.19)',
            dark: '#9f6464',
        },
        primary: {
            main: '#1D3440',
            light: 'rgba(71,94,107,0.55)',
            dark: '#000d1a',
        },
        positive: {
            main: '#8dae67',
            light: 'rgba(160,178,139,0.13)',
            dark: '#6ab612',
        },
        info: {
            main: 'hsl(191,41%,48%)',
            light: 'hsla(192,23%,57%,0.13)',
            dark: 'hsl(191,77%,38%)',
        },
        warning2: {
            main: '#d79b67',
            light: 'rgba(215,182,153,0.14)',
            dark: '#dc7318',
        },


        gray: {
            main: '#77858f',
            light: '#D3D3D3',
            dark: 'hsl(206,8%,12%)',
        }
    },
    typography: {
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            'Oxygen',
            'Ubuntu',
            'Cantarell',
            '"Fira Sans"',
            '"Droid Sans"',
            '"Helvetica Neue"',
            'sans-serif',
        ],
    },
    components: {
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    zIndex: 'unset',
                },
            },
        },
    },
};

export default theme;
