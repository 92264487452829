import React, {useState} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import SummaryBadge from './SummaryBadge';
import Button from '@mui/material/Button';
import { downloadJobUpload, } from '../../Apis/JobRole';
import { saveBase64 } from '@a2d24/downloader/helpers';
import { useSnackbar } from 'notistack';
import DownloadIcon from '@mui/icons-material/Download';

const UploadDetailPanel = ({attempt})=>{
    const classes = useStyles();
    const [downloading, setDownloading] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const handleJobUploadAttemptDownload = (objectKey)=>{
        if(downloading){
            return;
        }
        setDownloading(true);
        downloadJobUpload(objectKey).then(
            (result)=>{
                saveBase64(result, 'text/csv', null, `job_upload`);
            }
        ).catch(
            (error)=>{
                enqueueSnackbar('Failed to download Job Upload', { variant: 'error' });
            }
        ).finally(
            ()=>{
                setDownloading(false);
            }
        )
    }
    return(
        <div className={classes.root}>
            <div className={classes.subContainerDiv}>
                <div className={classes.subHeader}>
                    Uploaded document
                </div>
                <div className={classes.rowDiv}>
                    <Button
                        onClick={() => {
                            handleJobUploadAttemptDownload(attempt.source_upload_path)
                        }}
                        style={{ margin: '5px', display: 'flex', gap: '5px'}}
                        variant="outlined"
                        color={'primary'}
                        size={'small'}
                    >
                        <DownloadIcon className={classes.actionIcon} size={'small'}/>
                        {"job_upload.csv"}
                    </Button>
                </div>

            </div>
            <div className={classes.subContainerDiv}>
                <div className={classes.subHeader}>
                    Upload Summary
                </div>
                <div className={classes.rowDiv}>
                    <SummaryBadge statKey={'removals'} statValue={`${attempt.summary.num_removals} Removals`}/>
                    <SummaryBadge statKey={'additions'} statValue={`${attempt.summary.num_adds} Additions`}/>
                    <SummaryBadge statKey={'updates'} statValue={`${attempt.summary.num_updates} Updates`}/>
                </div>
            </div>
            {
                (attempt.upload_errors.length > 0 || attempt.general_error)?
                    <div className={classes.subContainerDiv}>
                        <div className={classes.subHeader}>
                            Upload Errors
                        </div>
                        <div className={classes.errorsDiv}>
                            <div>
                                {attempt?.general_error}
                            </div>
                            {attempt.upload_errors.map((error, index)=>{
                                return(
                                    <div key={index}>
                                        {`Row ${error.item.row} (${error.item.job_role_input}) - ${error.upload_error}`}
                                    </div>
                                )
                            })}

                        </div>
                    </div>
                    : null
            }


        </div>
    )
}

export default UploadDetailPanel;

const useStyles = makeStyles((theme) => ({
    root: {
        padding: '15px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start'
    },
    rowDiv: {
        display: 'flex',
        flexDirection: 'row',
        gap: '10px',
        justifyContent: 'flex-start',
        padding: '5px'
    },
    subContainerDiv: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '100%',
        gap: '5px'
    },
    subHeader: {
        fontWeight: 'bold',
        fontSize: '14px',
        paddingBottom: '5px',
        paddingBTop: '5px'
    },
    downloadButton: {
        borderRadius: '5px',
        border: '1px solid black',
        margin: '5px'
    },
    errorsDiv: {
        display: 'flex',
        flexDirection: 'column',
        gap: '5px',
        justifyContent: 'flex-start',
        padding: '5px',
        color: "#666666",
        maxHeight: '150px',
        overflowY: 'scroll',
        width: '100%'
    },
}));
