import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import { uploadStatusMapping } from '../const';
import { reFormat } from '../utils';


const UploadedByCell = ({ attempt }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div className={classes.type}>
                {attempt.uploaded_by}
            </div>
        </div>
    );
};

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        alignItems: 'center',
    },
    type: {
        fontSize: '14px',
        color: '#666666',
        lineHeight: '15px',
    },
}));

export default UploadedByCell;
