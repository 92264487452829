import axios from 'axios';

const API_URL = process.env.REACT_APP_API_ENDPOINT;



export const fetchJobRolesForClient = (client_id) => {
    return axios
        .post(`${API_URL}/RolesController/get_job_roles_for_client`, {
            client_id: client_id,
        })
        .then((r) => r.data);
};

export const getJobRolePresignedUrl  = (content_type, media_extension) => {
    return axios
        .post(`${API_URL}/RolesController/create_presigned_job_roles_post_url`, {
            content_type: content_type,
            file_extension: media_extension,
        })
        .then((r) => {
            return r.data[0];
        });
};


export const fetchJobUploadAttempts  = () => {
    return axios
        .get(`${API_URL}/RolesController/get_full_job_upload_attempts`)
        .then((r) => {
            return r.data;
        });
};

export const downloadJobUploadTemplate = () => {
    return axios
        .get(`${API_URL}/RolesController/get_job_role_upload_template_as_base64`)
        .then((r) => r.data);
};


export const downloadJobRoles = () => {
    return axios
        .get(`${API_URL}/RolesController/get_all_job_roles_as_csv_as_base64`)
        .then((r) => r.data);
};

export const downloadMedicalRoles = () => {
    return axios
        .get(`${API_URL}/RolesController/get_all_medical_roles_as_csv_as_base64`)
        .then((r) => r.data);
};

export const downloadJobUpload = (job_upload_object_key) => {
    return axios
        .post(`${API_URL}/RolesController/get_job_role_upload_as_base64`, {
            job_upload_object_key: job_upload_object_key,
        })
        .then((r) => r.data);
};
