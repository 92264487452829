export const getDefaultPageForRole = (roleID) =>{
    switch(roleID){
        case "practice_administrator":
            return "dashboard"
        case "clinician":
            return "dashboard"
        case "technician":
            return "dashboard"
        case "dsp_omp":
            return "dashboard"
        case "ho_admin":
            return "dashboard"
        case "ho_omp":
            return "dashboard"
        case "global_omp":
            return "dashboard"
        case "bookings_administrator":
            return "booking-dashboard"
        case "system_administrator":
            return "job-role-dashboard"
        default:
            return "dashboard"
    }
}
