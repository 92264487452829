import React from 'react';
import {getJobRolePresignedUrl} from '../../Apis/JobRole'
import UppyDashboard from '@a2d24/uppy';

const JobRoleUploader = (props) => {
const maxFileSize = props.maxFileSize??5;
    const onAfterUpload = async (file, plugin) => {
        plugin.uppy.emit('postprocess-progress', file, {
            mode: 'indeterminate',
            message: 'Adding metadata',
        });
        plugin.uppy.setFileState(file.id, {
            meta: {
                ...file.meta,
            },
        });
        window.location.reload();
    };

    return (
        <UppyDashboard
            s3PreSignAdapter={(file) => {
                return getJobRolePresignedUrl(
                    file.type,
                    file.extension
                )
            }}
            plugins={[]}
            height={'100%'}
            width={'100%'}
            onAfterUpload={onAfterUpload}
            options={{
                autoProceed: true,
                hideAfterFinish: true,
                restrictions: {
                    maxFileSize: maxFileSize * 1024 * 1024,
                    allowedFileTypes: ['.csv'],
                    maxNumberOfFiles: 1,
                },
            }}
            hideProgressAfterFinish={true}
            maxFileSize={maxFileSize}
        />
    );
};

export default JobRoleUploader;
