import React, { useState } from 'react';
import { Field } from 'react-final-form';
import { InputAdornment } from '@mui/material';
import { TextField } from 'mui-rff';
import FieldLayout from './FieldLayout';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Checkbox from '@mui/material/Checkbox';
import { HPCSADetailsView } from './HPCSADetailsView';


const APP_API_URL = process.env.REACT_APP_API_ENDPOINT;

const validPrefixes = [
    'MP',
    'DP',
    'PS',
]

const Circle = ({color, size})=>{
    return(
        <span
            style={{
                  height: size,
                  width: size,
                  backgroundColor: color,
                  borderRadius: '50%',
                  display: 'inline-block',
            }}
        />
    )
}

const VerificationStatusAdornment = ({color, size, title}) => {
    return(
        <InputAdornment position="end">
            <Circle size={size} color={color}/>
            <div style={{position:'absolute', color:color, top: '30px', right: '0px'}}>
                {title}
            </div>
        </InputAdornment>
    )
}

const HPCSAField = ({ id, name, optional, unit, description, warning }) => {
    const label = name;
    // const [verificationCount, setVerificationCount] = useState(0);
    const [verifying, setVerifying] = useState(false);
    const [confirmedUnverified, setConfirmedUnverified] = useState(false);
    const [verificationStatus, setVerificationStatus] = useState(null);
    const [currentValue, setCurrentValue] = useState(null);
    const [hpcsaDetails, setHPCSADetails] = useState({ });

    const verifyHPCSA = async (value)=>{
        setVerifying(true);
        return axios.post(`${APP_API_URL}/HPCSAController/verify_hpcsa_number`, {'hpcsa_number': value})
            .then((r)=>
            {
                if(r.status === 200){
                    setHPCSADetails(r.data);
                    return 'verified'
                }
            }
            ).catch((err)=>{
                 if(err?.response?.status === 404){
                    return 'unverified'
                 }
                return 'timeout'
            }).finally(
                ()=>{
                    setVerifying(false);
                }
            )
    }
    const getEndAdornment = () => {
        if(verifying){
            return(
                <InputAdornment position="end">
                    <CircularProgress color="inherit" size={'15px'} />
                    <div style={{position:'absolute', color:'inherit', top: '30px', right: '0px'}}>
                        Checking with HPCSA
                    </div>
                </InputAdornment>
            )
        }
        if(verificationStatus === 'verified'){
            return(
                <VerificationStatusAdornment color={'#6caa9c'} title={"HPCSA Verified"} size={'15px'}/>
            )
        }
        if(verificationStatus === 'unverified'){
            return(
                <VerificationStatusAdornment color={'#d29292'} title={"HPCSA Invalid"} size={'15px'}/>
            )
        }
        if(verificationStatus === 'timeout'){
            return(
                <VerificationStatusAdornment color={'#e9bf7a'} title={"No response from HPCSA"} size={'15px'}/>
            )
        }


    }
    return (
        <FieldLayout description={description} warning={warning}>
            <Field
                name={id}
                placeholder={label}
                optional={optional}
                unit={unit}
                validate={async (value)=>{
                    if(value !== currentValue) {
                        setCurrentValue(value);
                        const prefixMatches = validPrefixes.filter((prefix) => {
                            return (value ?? "").toUpperCase().startsWith(prefix);
                        })
                        if (prefixMatches.length === 0) {
                            return "Invalid HPCSA number"
                        }
                        if (value.length !== 9) {
                            return "Invalid HPCSA number"
                        }
                        const validStatus = await verifyHPCSA(value);
                        setVerificationStatus(validStatus);
                        if (validStatus === 'verified') {
                            return undefined;
                        }
                        if (validStatus === 'unverified') {
                            return undefined;
                        }
                        if (validStatus === 'timeout') {
                            return undefined;
                        }
                    }
                }}
            >
                {(fieldProps) => {
                    return (
                        <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                            <TextField
                                variant="standard"
                                label={fieldProps.placeholder}
                                name={fieldProps.input.name}
                                testid={id}
                                margin="none"
                                InputProps={{
                                    endAdornment: getEndAdornment()
                                }}
                                style={{ marginBottom: '1rem' }}
                                autoComplete="off"
                                required={optional === false}
                            />
                        </div>
                    );
                }}
            </Field>
                {
                    verificationStatus === 'unverified'?
                        <div>
                            <div>
                                Please note, the HPCSA number captured is not linked to any known practitioner. Please confirm you captured it as per the submitted sick note and proceed with the remaining details
                            </div>
                            <div style={{marginTop: '10px', display: 'flex', alignItems: 'center', color: 'rgba(0, 0, 0, 0.54)'}}>
                                <Field
                                    name={'confirmed_unverified'}
                                    placeholder={''}
                                    optional={false}
                                >
                                    {(fieldProps) => {
                                        return (
                                            <div style={{display: 'flex', flexDirection: 'column', gap: '10px'}}>
                                                <Checkbox
                                                    id="confirmed_unverified"
                                                    checked={confirmedUnverified}
                                                    onChange={(event) => setConfirmedUnverified(event.target.checked)}
                                                    css={{backgroundColor: 'blue'}}
                                                    required
                                                />
                                            </div>
                                        );
                                    }}
                                </Field>
                            <div>I confirm that I have captured the HPCSA number as referenced on the sick note</div>
                        </div>

                    </div>
                        :
                    null
            }
                            {
                    verificationStatus === 'verified'?
                        <HPCSADetailsView hpcsa_details={hpcsaDetails}/>
                        :
                    null
            }
        </FieldLayout>
    );
};

export default HPCSAField;
