import makeStyles from '@mui/styles/makeStyles';
import { CopyIcon } from '@a2d24-icons/copy-icon';
import IconButton from '@mui/material/IconButton';

const CopyInformation = ({title, value}) => {
    const classes = useStyles();
    return(
        <div className={classes.copyInformationContainer}>
            <div className={classes.title}>{title}</div>
            <div className={classes.value}>{value}</div>
            <div className={classes.icon}>

                <IconButton
                    style={{
                        cursor: 'pointer',
                        backgroundColor: 'transparent'
                    }}
                    onClick={() => {
                        navigator.clipboard.writeText(value);
                    }}
                    aria-label='Copy'
                >
                    <CopyIcon color={'#1D3440'} />
                </IconButton>
            </div>
        </div>
    )

}

export const HPCSADetailsView = ({hpcsa_details}) => {
    const classes = useStyles();
    return(
        <div className={classes.informationContainer}>
            <CopyInformation title={'Name'} value={`${hpcsa_details.first_names} ${hpcsa_details.last_name}`.replace("  ", " ")} />
            <CopyInformation title={'HPCSA'} value={hpcsa_details.registration_number.toUpperCase()} />
        </div>
    )
}

const useStyles = makeStyles((theme) => ({
    informationContainer: {
        borderRadius: '5px',
        display: 'flex',
        flexDirection: 'column',
        padding: '15px',
        marginTop: '15px',
        marginBottom: '15px'
    },
    copyInformationContainer: {
        display: 'grid',
        gridTemplateColumns: '20% 70% auto',
    },
    title: {
        display: 'flex',
        color: theme.palette.primary.main
    },
    value: {
        display: 'flex',
        color: theme.palette.gray.main,
    },
    icon: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
}));

