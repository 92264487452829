import React from 'react';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';
import makeStyles from '@mui/styles/makeStyles';

import JobRoleUploadDialog from './JobRoleUploadDialog';

const useStyles = makeStyles((theme) => ({
    fab: {
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 20,
        left: 'auto',
        position: 'fixed',
        zIndex: 3,
    },
    addIcon: {
        marginRight: theme.spacing(1),
        zIndex: 3,
    },
}));



const JobRoleUploadButton = (props) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const openDialog = ()=>{
        setOpen(true);
    }
    const closeDialog = ()=>{
        setOpen(false);
    }

    return(
        <div>
            <Fab
                color="primary"
                aria-label="add"
                className={classes.fab}
                variant="extended"
                onClick={openDialog}
            >
                <AddIcon className={classes.addIcon} />

                Add/Change Job Roles
            </Fab>
            <JobRoleUploadDialog open={open} onClose={closeDialog}/>
        </div>
    )
};

export default JobRoleUploadButton;
